<template>
  <div class="d-flex justify-content-end">
    <!-- <b-button
			v-if="mode === 'edit'"
			@click="$emit('show')"
			variant="primary"
			pill
			size="sm"
			style="height: 40px; width: 40px"
			class="d-flex align-items-center justify-content-center"
			><i class="mdi mdi-eye"></i
		></b-button> -->
    <!-- 
    <b-button
      v-if="mode === 'show' && edit"
      @click="$emit('edit')"
      variant="primary"
      pill
      size="sm"
      style="height: 40px; width: 40px"
      class="d-flex align-items-center justify-content-center"
      ><i class="mdi mdi-pencil"></i
    ></b-button> -->
    <b-button
      v-if="mode === 'show' || printer"
      @click="$emit('print')"
      variant="primary"
      pill
      size="sm"
      style="height: 40px; width: 40px"
      class="d-flex align-items-center justify-content-center"
      ><i class="mdi mdi-printer"></i
    ></b-button>
    <!-- <b-button
      v-if="mode === 'show' || mode === 'edit'"
      variant="primary"
      pill
      size="sm"
      style="height: 40px; width: 40px"
      class="d-flex align-items-center justify-content-center"
      ><i class="mdi mdi-delete"></i
    ></b-button> -->
    <b-button
      @click="$emit('close')"
      class="d-flex align-items-center justify-content-center"
      variant="primary"
      pill
      size="sm"
      style="height: 40px; width: 40px"
      ><i class="mdi mdi-close"></i
    ></b-button>
  </div>
</template>

<script>
export default {
  props: {
    mode: {
      type: String,
      default: null,
    },
    printer: {
      type: Boolean,
      default: false,
    },
    edit: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style></style>
