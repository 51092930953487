<template>
  <div>
    <div ref="file_picker" class="upload ml-2">
      <slot><input id="livrable" v-on:change="change" type="file" /></slot>
      <button
        type="button"
        :disabled="disabled"
        class="custom-upload btn btn-success px-4"
        @click="showUpload"
      >
        Ajouter fichier
      </button>
      <small :class="{ invalid: !valid }">
        {{ files.length }}
        Fichier(s) sélectionné(s)
      </small>
      <ul class="files" style="list-style: none">
        <li style="margin: 10px 0px" :key="index + 'Sito'" v-for="(file, index) in files">
          <button class="badge badge-danger" @click="removeFile(index)">x</button>
          {{ file.name }}
        </li>
      </ul>
    </div>
  </div>
</template>

<style>
input[type="file"] {
  border: 1px solid yellow;
}

input[type="file"].slotted {
  border: 1px solid rebeccapurple;
}

.invalid {
  color: red;
}
</style>

<script>
export default {
  props: {
    max: {
      type: Number,
      default: 1,
    },
    value: Array,
    isfinance: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      files: [],
      input: null,
    };
  },

  mounted() {
    // Find input
    this.input = this.$el.querySelector("input[type=file]");
    this.input.addEventListener("change", () => this.onFileSelection());
    this.input.style.display = "none";

    // Set multiple attribute on input, if max is more than one
    if (this.max > 1) {
      this.input.setAttribute("multiple", "multiple");
    } else {
      this.input.removeAttribute("multiple");
    }

    // Populate internal value, if external value is given,
    // attempt to populate external value by firing event if not
    if (this.value) {
      this.files = this.value;
    } else {
      this.$emit("input", []);
    }
  },

  methods: {
    /**
     * Executed, when the user selects a (or multiple) new file(s)
     * @returns {void}
     */
    onFileSelection() {
      // add all selected files
      for (let file of this.input.files) {
        this.files.pop();
        this.files.push({ name: file.name });
      }

      // reset file input
      this.input.value = null;
    },

    /**
     * Removes the file with the given index
     * @param {number} index
     * @returns {void}
     */
    removeFile(index) {
      this.files.splice(index, 1);
    },

    showUpload() {
      const event = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      //   console.log(event);
      this.input.dispatchEvent(event);
    },
    change() {
      // var formData = new FormData();
      var files = [];
      for (
        let index = 0;
        index < document.getElementById("livrable").files.length;
        index++
      ) {
        var formData = new FormData();
        var file = document.getElementById("livrable").files[index];
        formData.append("file", file);

        files.push(formData);
      }

      // formData.append("file", document.getElementById("livrable").files[0]);

      this.$emit("change", files);
      if (this.isfinance) {
        this.files = [];
        file = [];
      }
    },
  },

  computed: {
    valid() {
      return this.files.length <= this.max;
    },
  },

  watch: {
    files(files) {
      this.$emit("input", files);
    },
  },
};
</script>
