<template>
  <div>
    <div>
      <b-card>
        <div class="d-flex">
          <span class="h2 mr-auto text-secondary"></span>

          <div class="row mb-3">
            <div class="col-12">
              <modal-actions @close="$router.go(-1)"/>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 d-flex flex-wrap justify-content-between">
            <span class="h2 mr-auto text-secondary">Nouvelle inscription</span>
            <div>
              <b-button type="submit" pill @click="savedata"> Confirmer </b-button>
            </div>
          </div>
        </div>
        <br />
        <div class="d-flex flex-wrap">
          <div class="shdow col-md-4">
            <div class="d-flex flex-wrap justify-content-between align-items-center">
              <p class="h4 text-secondary my-auto mr-auto">Informations générales</p>

              <hr class="bg-secondary mb-3 mt-1 w-100" />
            </div>

            <div class="row">
              <div class="col-12">
                <b-form-group
                  class="font-weight-bold"
                  label="Présentateur :"
                  label-cols-sm="3"
                  label-align-sm="left"
                >
                  <div class="font-weight-normal">
                    <v-select
                      :options="responsables"
                      placeholder="Responsable"
                      name="responsable"
                      label="text"
                      :reduce="(responsable) => responsable.value"
                      v-model="newInscription.user"
                      style="padding-right: 0 !important"
                      :class="{
                        'is-invalid form-control p-0':
                          $v.newInscription.user.$error && $v.newInscription.user.$dirty,
                      }"
                    />
                    <span
                      style="font-size: x-small"
                      class="text-danger font-weight-normal"
                      v-if="$v.newInscription.user.$dirty"
                    >
                      {{ !$v.newInscription.user.required ? "Champ obligatoire" : "" }}
                    </span>
                  </div>
                </b-form-group>
                <b-form-group
                  label="Session :"
                  class="font-weight-bold"
                  label-cols-sm="3"
                  label-align-sm="left"
                >
                  <div class="font-weight-normal">
                    <v-select
                      :options="session"
                      placeholder="Session"
                      label="text"
                      :reduce="(sessions) => sessions.value"
                      v-model="newInscription.session"
                      style="padding-right: 0 !important"
                      :class="{
                        'is-invalid form-control p-0':
                          $v.newInscription.session.$error &&
                          $v.newInscription.session.$dirty,
                      }"
                    />
                    <span
                      style="font-size: x-small"
                      class="text-danger font-weight-normal"
                      v-if="$v.newInscription.session.$dirty"
                    >
                      {{ !$v.newInscription.session.required ? "Champ obligatoire" : "" }}
                    </span>
                  </div>
                </b-form-group>
                <b-form-group
                  label="Date : "
                  class="font-weight-bold"
                  label-cols-sm="3"
                  label-align-sm="left"
                >
                  <!--b-form-group
                      class="font-weight-bold"
                      label="Dossier:"
                      label-cols-sm="3"
                      label-align-sm="left"
                  >
                    <b-form-select
                        :options="dossier"
                        placeholder="Dossier"
                        name="responsable"
                        label="full_name"
                        :reduce="(dossier) => dossier.id"
                        v-model="newInscription.dossier"
                        class="font-weight-normal"
                        required
                    ></b-form-select>
                  </b-form-group-->

                  <date-picker
                    v-model="newInscription.date"
                    class="col-12 p-0 mb-2 font-weight-normal"
                    placeholder="Date de début"
                    format="DD/MM/YYYY"
                    style="padding-right: 0 !important; height: 44.22px"
                    :class="{
                      'is-invalid form-control p-0':
                        $v.newInscription.date.$error && $v.newInscription.date.$dirty,
                    }"
                  />
                  <span
                    style="font-size: x-small"
                    class="text-danger font-weight-normal"
                    v-if="$v.newInscription.date.$dirty"
                  >
                    {{ !$v.newInscription.date.required ? "Champ obligatoire" : "" }}
                  </span>
                </b-form-group>
              </div>
            </div>
          </div>
          <div class="shdow col-md-4">
            <div class="d-flex flex-wrap justify-content-between align-items-center">
              <p class="h4 text-secondary my-auto mr-auto">Dossier</p>
              <hr class="bg-secondary mb-3 mt-1 w-100" />
            </div>

            <div class="row">
              <div class="col-12">
                <b-form-group
                  class="font-weight-bold"
                  label="dossier :"
                  label-cols-sm="3"
                  label-align-sm="left"
                >
                  <b-form-input
                    v-model="newDossier.dossier"
                    placeholder="Dossier"
                    :class="{
                      'is-invalid':
                        $v.newDossier.dossier.$error && $v.newDossier.dossier.$dirty,
                    }"
                  />
                  <span
                    style="font-size: x-small"
                    class="text-danger font-weight-normal"
                    v-if="$v.newDossier.dossier.$dirty"
                  >
                    {{ !$v.newDossier.dossier.required ? "Champ obligatoire" : "" }}
                  </span>
                </b-form-group>
                <b-form-group
                  label="Type :"
                  class="font-weight-bold"
                  label-cols-sm="3"
                  label-align-sm="left"
                >
                  <b-form-select
                    v-model="newDossier.type"
                    :options="['Initiative', 'Feuille de route']"
                    @change="changetype"
                    :class="{
                      'is-invalid':
                        $v.newDossier.type.$error && $v.newDossier.type.$dirty,
                    }"
                  ></b-form-select>
                  <span
                    style="font-size: x-small"
                    class="text-danger font-weight-normal"
                    v-if="$v.newDossier.type.$dirty"
                  >
                    {{ !$v.newDossier.type.required ? "Champ obligatoire" : "" }}
                  </span>
                </b-form-group>

                <div v-if="typeid !== ''">
                  <div v-if="typeid">
                    <b-form-group
                      label="Initiative :"
                      class="font-weight-bold"
                      label-cols-sm="3"
                      label-align-sm="left"
                    >
                      <div class="font-weight-normal">
                        <v-select
                          label="text"
                          :reduce="(initiative) => initiative.value"
                          :options="initiatives"
                          placeholder="Initiative"
                          v-model="newDossier.Intiative"
                        />
                      </div>
                    </b-form-group>
                  </div>
                  <b-form-group
                    v-else
                    label="Feuille de route :"
                    class="font-weight-bold"
                    label-cols-sm="3"
                    label-align-sm="left"
                  >
                    <div class="font-weight-normal">
                      <v-select
                        label="text"
                        :reduce="(feuille) => feuille.value"
                        :options="feuille"
                        placeholder="Feuille de route"
                        v-model="newDossier.fueille"
                        class="font-weight-normal"
                      />
                    </div>
                  </b-form-group>
                </div>

                <b-form-group
                  label="File : "
                  class="font-weight-bold"
                  label-cols-sm="3"
                  label-align-sm="left"
                >
                  <!--b-form-group
                      class="font-weight-bold"
                      label="Dossier:"
                      label-cols-sm="3"
                      label-align-sm="left"
                  >
                    <b-form-select
                        :options="dossier"
                        placeholder="Dossier"
                        name="responsable"
                        label="full_name"
                        :reduce="(dossier) => dossier.id"
                        v-model="newInscription.dossier"
                        class="font-weight-normal"
                        required
                    ></b-form-select>
                  </b-form-group-->
                  <br />
                  <DossierFile
                    @change="filePickerChanger"
                    @click.prevent=""
                    class="font-weight-normal ml-2"
                  />
                </b-form-group>
              </div>
            </div>
          </div>
          <div class="shdow col-md-4">
            <div class="d-flex flex-wrap justify-content-between align-items-center">
              <p class="h4 text-secondary my-auto mr-auto">Décision</p>
              <hr class="bg-secondary mb-3 mt-1 w-100" />
            </div>

            <div class="row">
              <div class="col-12">
                <b-form-group
                  class="font-weight-bold"
                  label="Décision :"
                  label-cols-sm="3"
                  label-align-sm="left"
                >
                  <b-form-input
                    v-model="decision"
                    :class="{
                      'is-invalid': $v.decision.$error && $v.decision.$dirty,
                    }"
                  ></b-form-input>
                  <span
                    style="font-size: x-small"
                    class="text-danger font-weight-normal"
                    v-if="$v.decision.$dirty"
                  >
                    {{ !$v.decision.required ? "Champ obligatoire" : "" }}
                  </span>
                </b-form-group>
                <b-form-group
                  label="Commentaire :"
                  class="font-weight-bold"
                  label-cols-sm="3"
                  label-align-sm="left"
                >
                  <textarea
                    class="form-control W-100"
                    id="exampleFormControlTextarea1"
                    v-model="commentaire"
                    rows="9"
                  ></textarea>
                </b-form-group>
              </div>
            </div>
          </div>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import countries from "@/assets/countries/data.json";
import ModalActions from "@/components/custom/ModalActions.vue";

import { mapGetters } from "vuex";
import { required } from "vuelidate/lib/validators";
import moment from "moment";
import Swal from "sweetalert2";
import DossierFile from "@/components/custom/DossierFile";
import { USER_URL, INSCRIPTION_URL, INITIATIVE_URL, FDR_URL } from "@/helpers/constURL";

export default {
  components: { ModalActions, DossierFile },
  data: () => ({
    click: false,
    countries: countries,
    selectedCountries: [],
    selectedResponsable: null,
    selectedSession: null,
    selectedRole: null,
    selectedDossier: null,
    typeid: "",
    decision: "",
    dossier: null,
    type: "",
    commentaire: "",
    newInscription: {
      user: [],
      session: [],
      date: null,
    },
    newDossier: {
      Intiative: "",
      fueille: "",
      dossier: null,
      type: "",
    },
  }),
  validations: {
    decision: {
      required,
    },
    newInscription: {
      user: {
        required,
      },
      date: {
        required,
      },
      session: {
        required,
      },
    },
    newDossier: {
      type: {
        required,
      },
      dossier: {
        required,
      },
    },
  },
  methods: {
    filePickerChanger(files) {
      this.dossier = files;
    },
    changetype() {
      if (this.newDossier.type == "Initiative") {
        this.typeid = true;
      } else if (this.newDossier.type == "Feuille de route") {
        this.typeid = false;
      } else {
        this.typeid = "";
      }
    },
    savedata: function () {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.click = true;
        var data = {
          user: `/api/${USER_URL}/${this.newInscription.user}`,
          session: `${this.newInscription.session}`,
          date: moment(this.newInscription.date).format("DD/MM/YYYY HH:MM:SS"),
        };
        this.$store.dispatch("manage/createInscription", data).then(() => {
          var decision = {
            inscription: `/api/${INSCRIPTION_URL}/${this.INSCRIPTION.id}`,
            decision: this.decision,
            commentaire: this.commentaire,
          };

          var dossier;
          if (this.typeid) {
            dossier = {
              inscription: `/api/${INSCRIPTION_URL}/${this.INSCRIPTION.id}`,
              decision: this.newDossier.dossier,
              initiative: `/api/${INITIATIVE_URL}/${this.newDossier.Intiative}`,
              name: this.newDossier.dossier,
            };
          } else {
            dossier = {
              inscription: `/api/${INSCRIPTION_URL}/${this.INSCRIPTION.id}`,
              name: this.newDossier.dossier,
              feuileroute: `/api/${FDR_URL}/${this.newDossier.fueille}`,
            };
          }
          this.$store.dispatch("decision/createDecision", decision);
          this.$store.dispatch("dossier/createDossier", dossier).then(() => {
            this.dossier.map((data) => {
              this.$store.dispatch("dossier/createDossierFile", {
                id: this.DOSSIERS.id,
                file: data,
              });
            });
          });

          this.submitingForm = false;
          Swal.fire({
            title: "L'inscription est bien créé !",
            type: "success",
            confirmButtonText: "OK",
          }).then((result) => {
            if (result.value) {
              this.$router.push({
                name: "show-inscription",
                params: {
                  slug: this.INSCRIPTION.slug ? this.INSCRIPTION.slug : "5958ds7",
                  id: this.INSCRIPTION.id,
                },
              });
            }
          });
        });
      }
    },
  },
  created() {
    this.$store.dispatch("users/fetchAllResponsables");
    this.$store.dispatch("role/fetchAllContributorRoles");
    this.$store.dispatch("manage/fetchAllSessions");
    this.$store.dispatch("initiative/fetchAllInitiatives");
    this.$store.dispatch("fdr/fetchAllFdrs");
  },
  computed: {
    ...mapGetters("role", ["CONTRIBUTOR_ROLES"]),
    ...mapGetters("users", ["RESPONSABLES"]),
    ...mapGetters("manage", ["SESSIONS"]),
    ...mapGetters("dossier", ["DOSSIERS"]),
    ...mapGetters("manage", ["INSCRIPTION"]),
    ...mapGetters("fdr", ["FDRS"]),
    ...mapGetters("initiative", ["INITIATIVES"]),
    initiatives() {
      //return array of responsables
      return this.INITIATIVES.map((initiative) => ({
        value: initiative.id,
        text: initiative.libelle,
      }));
    },
    feuille() {
      //return array of responsables
      return this.FDRS.map((fdr) => ({
        value: fdr.id,
        text: fdr.name,
      }));
    },
    responsables() {
      //return array of responsables
      return this.RESPONSABLES.map((responsable) => ({
        value: responsable.id,
        text: responsable.firstname + " " + responsable.lastname,
      }));
    },
    session() {
      var data = this.SESSIONS.map((session) => ({
        value: session["@id"],
        text: session.name,
      }));

      return data;
    },
  },
};
</script>

<style></style>
